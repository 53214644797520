.Grid {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
}

.Grid__item {
    box-sizing: border-box;
    min-width: 350px;
    padding: 0 10px;
    width: 50%;
}

.Grid__item--full-size {
    width: 100%;
}

.Grid__item--button {
    margin-top: 30px;
}