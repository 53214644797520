.Input {
    background-color: #ccc;
    border-radius: 8px;
    border: 2px solid #fff;
    font-size: 16px;
    outline: none;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
}

.Input:focus {
    border-color: aqua;
}

.Input--error {
    border-color: red;
    margin-bottom: 0;
}