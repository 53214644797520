.Table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;
    padding: 12px;
    width: 100%;
}

.Table__row {
    border-bottom: 1px solid #eee;
}

.Table__row--header {
    background-color: #ccc;
    color: #282c34;
}

.Table__row--body:nth-child(odd) {
    background-color: rgba(255,255,255,.2);
}

.Table__cell {
    padding: 12px;
    text-align: center;
    vertical-align: top;
}

.Table__cell:last-child {
    padding: 8px 24px 8px 0;
}
