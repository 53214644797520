.App {
  background-color: #282c34;
  box-sizing: border-box;
  color: white;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  padding: 15px 30px;
  text-align: center;
}

.App__content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;
}
