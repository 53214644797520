.Form {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 320px;
}

.Form__input {
    margin-top: 50px;
}

.Form__action {
    margin-top: 30px;
}