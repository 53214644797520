.Home {
    margin-top: 24px;
    width: 100%;
}

.Home__grid {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.Home__warning {
    color: yellow;
    display: block;
    font-size: 12px;
}

.Home__left {
    text-align: left;
}

.Home__right {}

.Home__list {
    margin-top: 40px;
}

.Home__action {
    margin-top: 60px;
}